@import "base-styles/variables";

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .table {
    th,
    td {
      background-color: transparent !important;
    }

    .deficient {
      background-color: $deficient !important;
    }

    .insufficient {
      background-color: $insufficient !important;
    }

    .satisfactory {
      background-color: $satisfactory !important;
    }

    .excessive {
      background-color: $excessive !important;
    }

    .toxic {
      background-color: $toxic !important;
    }

    .highrisk {
      background-color: $toxic !important;
    }

    .dry_cow {
      background-color: $dry-cow !important;
    }

    .springers {
      background-color: $springer !important;
    }

    .colostrums {
      background-color: $colostrum !important;
    }

    .early_milkers {
      background-color: $early-milker !important;
    }

    .peak_milkers {
      background-color: $peak-milker !important;
    }

    .late_milkers {
      background-color: $late-milker !important;
    }

    .r1_heifers {
      background-color: $r1-heifer !important;
    }

    .r2_heifers {
      background-color: $r2-heifer !important;
    }

    .ma_cows {
      background-color: $ma-cow !important;
    }

    .steer_calf {
      background-color: $steer-calf !important;
    }

    .r1_steer {
      background-color: $r1-steer !important;
    }

    .r2_steer {
      background-color: $r2-steer !important;
    }

    .mixed_age_steers {
      background-color: $ma-steer !important;
    }

    .mixed_age_bulls {
      background-color: $ma-bull !important;
    }

    .heifers_calves {
      background-color: $heifer-calf !important;
    }

    .bulls_calves {
      background-color: $bull-calf !important;
    }

    .r1_bulls {
      background-color: $r1-bull !important;
    }

    .r2_bulls {
      background-color: $r2-bull !important;
    }

    .ma_ewes {
      background-color: $ewe !important;
    }

    .ewe_hoggets {
      background-color: $ewe-hogget !important;
    }

    .two_tooths {
      background-color: $two-tooth !important;
    }

    .lambs {
      background-color: $lamb !important;
    }

    .rams {
      background-color: $ram !important;
    }
  }

  header {
    border: 0 !important;

    .user-menu {
      display: none;
    }
  }

  .breadcrumb {
    display: none;
  }

  .report {
    section,
    .two-columns > div,
    .ritch-text-renderer {
      break-inside: avoid;
    }

    .two-columns {
      column-count: 2;
      column-gap: 2rem;

      // Hack using table to avoid break
      // https://stackoverflow.com/a/7785711
      > div {
        page-break-inside: avoid;
        break-inside: avoid-column;
        display: table;
        width: 100%;
      }
    }
  }

  .sticky-bar-bottom {
    display: none;
  }
}
