@import "base-styles/variables";

.page-frame {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  border-bottom: 1px solid $secondary;
  margin-bottom: 1rem;

  .app-name {
    font-size: 28px;
  }

  .logo {
    height: 3rem;
    margin: 0.5rem 0;
  }
}

main {
  flex-grow: 1;
  margin-bottom: 2rem;
}

footer {
  // height: 3rem;
  background-color: $secondary;
  color: white;
  // padding-top: 1rem;
}