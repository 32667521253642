@import "base-styles/variables";

.sampling-event-card-title {
  font-weight: 700;

  a,
  a:hover {
    color: $body-color;
  }
}

.sampling-event-card-secondary-info {
  font-size: $font-size-sm;
  color: $gray-600;
}

.sampling-event-progress {
  text-align: right;
}

@include media-breakpoint-down(xs) {
  .sampling-event-card-status {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .text-muted {
      font-size: $font-size-sm;
    }
  }
}
