@import "base-styles/variables";

.deficient {
  background-color: $deficient;
}

.insufficient {
  background-color: $insufficient;
}

.satisfactory {
  background-color: $satisfactory;
}

.excessive {
  background-color: $excessive;
}

.toxic {
  background-color: $toxic;
}

.highrisk {
  background-color: $highrisk;
}
