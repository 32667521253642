@import "base-styles/variables";

.element-analysis-table {
  table-layout: fixed;
  min-width: 900px;
  text-align: right;

  thead {
    th {
      vertical-align: top;
    }
  }

  tbody {
    th {
      text-align: left;
    }
  }
}

.prescription-table {
  table-layout: fixed;
}
