@import "base-styles/variables";

.farm-info-table {
  border: 1px solid $gray-400;

  th {
    font-weight: 400;
  }

  td {
    font-weight: 700;
  }
}
