@import "base-styles/variables";

.report-summary-table {
  table-layout: fixed;

  .thead-element {
    width: 10rem;
  }

  .thead-status {
    width: 8rem;
  }

  .form-group {
    margin-bottom: 0;
  }
}
