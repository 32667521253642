@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// https://getbootstrap.com/docs/5.0/customize/sass/#colors
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

// Colours from the logo
$logo-text-blue: #39627b; // 6.54:1
$logo-text-green: #89c273; // 2.09:1
$logo-image-blue: #2b5b84; // 7.16:1
$logo-image-green1: #9dca7f; // 1.87:1
$logo-image-green2: #7cbc62; // 2.27:1
$logo-image-green3: #557f49; // 4.64:1

// Theme colours
$primary: $logo-image-green3;
$secondary: $logo-text-blue;

// Report colours
// Colours sampled from this page
// https://getbootstrap.com/docs/5.0/customize/color/
$deficient: #9ec5fe;
$insufficient: #c29ffa;
$satisfactory: $logo-image-green1;
$excessive: #fd9843;
$toxic: #e35d6a;
$highrisk: $toxic;

// Breadcrumbs
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider: quote(">");


@import "~bootstrap/scss/variables";

// Dairy
$dry-cow: tint-color($blue, 90%);
$springer: tint-color($yellow, 80%);
$colostrum: tint-color($primary, 70%);
$early-milker: tint-color($pink, 90%);
$peak-milker: tint-color($purple, 90%);
$late-milker: tint-color($green, 80%);
$r1-heifer: tint-color($red, 90%);
$r2-heifer: tint-color($orange, 80%);

// Beef
$ma-cow: tint-color($blue, 90%);
$steer-calf: tint-color($yellow, 80%);
$r1-steer: tint-color($primary, 70%);
$r2-steer: tint-color($pink, 90%);
$ma-steer: tint-color($purple, 90%);
$ma-bull: tint-color($green, 80%);
// $r1-heifer: tint-color($red, 90%); // already defined
// $r2-heifer: tint-color($orange, 80%); // already defined
$heifer-calf: tint-color($teal, 80%);
$bull-calf: tint-color($indigo, 90%);
$r1-bull: tint-color($primary, 70%);
$r2-bull: tint-color($pink, 90%);

// Sheep
$ewe: tint-color($blue, 90%);
$hogget: tint-color($yellow, 80%);
$ewe-hogget: tint-color($primary, 70%);
$two-tooth: tint-color($pink, 90%);
$lamb: tint-color($purple, 90%);
$ram: tint-color($green, 80%);

