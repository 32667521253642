@import "base-styles/variables";

.ritch-text-renderer {
  ul {
    padding-left: 1.5rem;

    li {
      list-style-type: disc;
    }
  }
}
