@import "base-styles/variables";

.field-array {
  .item-row:not(:first-child) {
    border-top: 1px solid $gray-400;
    padding-top: 0.5rem;
  }

  .btn {
    width: 100%;
    margin-bottom: 1rem;
  }
}
