@import "base-styles/variables";

.dry_cow {
  background-color: $dry-cow;
}

.springers {
  background-color: $springer;
}

.colostrums {
  background-color: $colostrum;
}

.early_milkers {
  background-color: $early-milker;
}

.peak_milkers {
  background-color: $peak-milker;
}

.late_milkers {
  background-color: $late-milker;
}

.r1_heifers {
  background-color: $r1-heifer;
}

.r2_heifers {
  background-color: $r2-heifer;
}

.ma_cows {
  background-color: $ma-cow;
}

.steer_calf {
  background-color: $steer-calf;
}

.r1_steer {
  background-color: $r1-steer;
}

.r2_steer {
  background-color: $r2-steer;
}

.mixed_age_steers {
  background-color: $ma-steer;
}

.mixed_age_bulls {
  background-color: $ma-bull;
}

.heifers_calves {
  background-color: $heifer-calf;
}

.bulls_calves {
  background-color: $bull-calf;
}

.r1_bulls {
  background-color: $r1-bull;
}

.r2_bulls {
  background-color: $r2-bull;
}

.ma_ewes {
  background-color: $ewe;
}

.ewe_hoggets {
  background-color: $ewe-hogget;
}

.two_tooths {
  background-color: $two-tooth;
}

.lambs {
  background-color: $lamb;
}

.rams {
  background-color: $ram;
}

.macro-mineral-supplementation-table {
  td {
    .form-label {
      display: none;
    }

    .form-group {
      text-align: left;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
