@import "base-styles/variables";

.farms-list {
  column-count: 2;

  @include media-breakpoint-up(md) {
    column-count: 3;
  }

  @include media-breakpoint-up(xl) {
    column-count: 4;
  }
}