@import "base-styles/variables";

form > fieldset > legend {
  text-transform: uppercase;
  font-weight: 700;
}

.plaintext-field {
  padding: 0.375rem 0;
  border: solid transparent;
  border-width: 1px 0;
}
