@import "./variables";
@import "~bootstrap/scss/bootstrap";

ul {
  list-style: none;
  padding: 0;

  &.list-style-disc {
    list-style: disc;
    padding-left: 1.5rem;
  }
}

.nav-tabs {
  margin-bottom: 1rem;
}

.report {
  h1 {
    background-color: $primary;
    color: white;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem;
  }

  h2 {
    background-color: $secondary;
    color: white;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem;
  }

  h3 {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    text-decoration: underline;
  }

  h4 {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-top: 0.5rem;

    &::after {
      content: ":";
    }
  }

  section {
    margin-bottom: 2rem;
  }

  table {
    text-align: center;
  }

  thead {
    background-color: $gray-200;

    th {
      vertical-align: middle;
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  .footnote {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(md) {
    .two-columns {
      column-count: 2;
      column-gap: 2rem;

      & > div {
        break-inside: avoid;
      }
    }
  }
}

.footnote {
  font-size: $font-size-sm;
}

.card-body {
  @include media-breakpoint-down(xs) {
    padding: 0.75rem;
  }
}
