@import "base-styles/variables";

.sticky-bar-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 0.75rem 0;
  border-top: 1px solid $border-color;
}

.spacer {
  margin-bottom: 3rem;
}
