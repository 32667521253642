@import "base-styles/variables";

// Override unwanted styles
.ql-container {
  font-family: revert;
  font-size: $font-size-base;
}

.ql-editor {
  p {
    margin-bottom: $paragraph-margin-bottom;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: $headings-margin-bottom;
  }

  ol,
  ul {
    padding-left: 0;
  }
}
